<template>
  <div class="w100p">
    <div class="display-flex align-center justify-between pv1-5 ph1 light-border-top-violet bg-light-blue text-violet f1-4 none-mb">
      <div class="text-left w21 " :class="{ 'w21': header, 'pl2': header === 'Vital' }" v-for="(header, key) in thresholdHeaders" :key="key">
        {{ header }}
      </div>
    </div>
    <bh-threshold-graph :showModal.sync="showThresholdGraph" :title="thresholdGraphTitle" :options="thresholdGraphOptions" />
    <template v-if="fullScreen">
      <div class="display-flex justify-between light-border-top-violet pa1" v-for="key in thresholdKeys" :key="key">
        <div class="display-flex justify-between-mb align-center w100p-mb w21 text-center f1-1 pa1-mb">
          <div class="display-flex ml2">
            <div v-if="currentRule.name !== 'Default Settings'" class="text-center">
              <drag class="mr1 pointer-move" v-if="!currentRule[key].is_included" :transfer-data="vitalPlaceholders[key].key">
                <img src="/assets/drag-icon.svg" alt="drag" height="15" width="15">
                <!-- <bh-icon icon="drag_indicator" class="w45p-mb text-violet f2-5 text-center" /> -->
              </drag>
              <bh-icon v-else icon="check-mark mr1 text-violet f1-5" />
            </div>
            <span class="text-left">{{ vitalPlaceholders[key].vital }}</span>
          </div>
          <div class="show-mb">
            <bh-icon icon="play-right" class="pr1-2 text-violet inline"  @output="toggleBottomSheet(key, vitalPlaceholders[key].vital)" />
          </div>
        </div>
        <div class="w21 text-center none-mb">
          <bh-input
            class="ph0-5 w50p border-grey br0-5"
            type="number"
            :toFixed="true"
            placeholder="Lowest"
            :value="currentRule[key].min"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[key], 'min', $event, key)" />
        </div>
        <div class="w21 text-center none-mb">
          <bh-input
            class="ph0-5 w50p border-grey br0-5"
            type="number"
            :toFixed="true"
            placeholder="Highest"
            :value="currentRule[key].max"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[key], 'max', $event, key)" />
        </div>
        <div class="w21 text-center none-mb">
          <div class="display-flex align-center border-grey br0-5 w50p">
            <bh-input
              class="ph0-5 border-right-grey"
              type="number"
              placeholder="Time Frame"
              :value="currentRule[key].time_frame"
              @keydown="onValueChange"
              @output="valueChanged(currentRule[key], 'time_frame', $event, key)" />
            <span class="w100p">Sec</span>
          </div>
        </div>
        <div class="w21 text-center none-mb">
          <div class="display-flex align-center border-grey br0-5 w50p">
            <bh-input
              class="ph0-5 border-right-grey"
              type="number"
              placeholder="Data Point"
              :value="currentRule[key].previous_events"
              @keydown="onValueChange"
              @output="valueChanged(currentRule[key], 'previous_events', $event, key)" />
          </div>
        </div>
        <div class="text-center none-mb w21">
          <div class="display-flex align-center border-grey br0-5 w50p">
            <bh-input
              class="ph0-5 border-right-grey"
              type="number"
              placeholder="Rate of Change"
              :value="currentRule[key].roc"
              @keydown="onValueChange"
              @output="valueChanged(currentRule[key], 'roc', $event, key)" />
            <span class="w50p">%</span>
          </div>
        </div>
        <div class="w21 text-center display-flex justify-around align-center none-mb">
          <!-- <img src="/assets/graph.svg" class="pointer" alt="open-graph" @click="loadThresholdGraph(vitalPlaceholders[key], currentRule[key])"> -->
          <span class="underline color-blue f1-2 mr2 pointer" @click.prevent="resetRule(key)">Clear</span>
        </div>
      </div>
    </template>
    <bh-bottom-sheet data-app class="show-mb" :value="showBottomSheet" @modalChange="closeModal" v-if="currentRule[bottomSheetKey]">
      <div class="text-left">
        <div class="f1-7 pa2 display-flex align-center justify-between">
          <span>{{ bottomSheetTitle }}</span>
          <img src="/assets/graph.svg" alt="open-graph" @click="loadThresholdGraph(vitalPlaceholders[bottomSheetKey], currentRule[bottomSheetKey])">
        </div>
      </div>
      <v-divider />
      <div class="display-flex flex-column align-center pt1-5 pb1-5">
        <div class="display-flex justify-between pa2 pt0-5 pb1-5">
          <span class="f1-6 color-blue pt0-5">Lowest Threshold Value</span>
          <bh-input
            class="light-border-violet f1-4 pl1 w50p"
            placeholder="Lowest"
            :value="currentRule[bottomSheetKey].min"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[bottomSheetKey], 'min', $event, bottomSheetKey)"/>
        </div>
        <v-divider />
        <div class="display-flex justify-between pa2 pt1-5 pb1-5">
          <span class="f1-6 color-blue pt0-5">Highest Threshold Value</span>
          <bh-input
            class="light-border-violet f1-4 pl1 w50p"
            placeholder="Highest"
            :value="currentRule[bottomSheetKey].max"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[bottomSheetKey], 'max', $event, bottomSheetKey)" />
        </div>
        <v-divider />
        <div class="display-flex justify-between pa2 pt1-5 pb1-5">
          <span class="f1-6 color-blue pt0-5">Rate of change</span>
          <bh-input
            class="light-border-violet f1-4 pl1 w50p"
            placeholder="Rate of Change"
            :value="currentRule[bottomSheetKey].roc"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[bottomSheetKey], 'roc', $event, bottomSheetKey)" />
        </div>
          <v-divider />
        <div class="display-flex justify-between pa2 pt1-5 pb1-5">
          <span class="f1-6 color-blue pt0-5">Data Point</span>
          <bh-input
            class="light-border-violet f1-4 pl1 w50p"
            placeholder="Data Point"
            :value="currentRule[bottomSheetKey].previous_events"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[bottomSheetKey], 'previous_events', $event, bottomSheetKey)" />
        </div>
        <v-divider />
        <div class="display-flex justify-between pa2 pt1-5 pb1-5">
          <span class="f1-6 color-blue pt0-5">Time Frame</span>
          <bh-input
            class="light-border-violet f1-4 pl1 w50p"
            placeholder="Time Frame"
            :value="currentRule[bottomSheetKey].time_frame"
            @keydown="onValueChange"
            @output="valueChanged(currentRule[bottomSheetKey], 'time_frame', $event, bottomSheetKey)" />
        </div>
      </div>
      <v-divider />
      <bh-button class="w100p f1-7 text-white bg-violet text-black pt2 pb2" @output="closeModal">Done</bh-button>
    </bh-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ThresholdGraph from './ThresholdGraph'

import { eventBus } from '@/services/global-bus'

import { vitalPlaceholders, thresholdKeys, thresholdHeaders } from '@/constants/thresholds'
import { getThresholdAndROCValues, mapThresholdGraphValues } from '@/services/configurations'
import { updateValue } from '@/utils'

export default {
  name: 'bh-threshold',
  components: {
    'bh-threshold-graph': ThresholdGraph
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('configurations', ['condition', 'currentRule'])
  },
  data() {
    return {
      showBottomSheet: false,
      bottomSheetKey: '',
      bottomSheetTitle: '',
      thresholdHeaderTitle: '',
      thresholdHeaders,
      thresholdKeys,
      vitalPlaceholders,
      showThresholdGraph: false,
      thresholdGraphOptions: [],
      thresholdGraphTitle: ''
    }
  },
  methods: {
    updateValue,
    ...mapActions('configurations', ['resetRule']),
    valueChanged(obj, key, val, thresholdKey) {
      this.updateValue(obj, key, val)
      eventBus.$emit('thresholdValueChanged', { obj, thresholdKey })
    },
    async loadThresholdGraph(threshold, currentRule) {
      const { content } = await getThresholdAndROCValues(threshold.key, currentRule.previous_events)
      this.thresholdGraphOptions = [
        { title: 'Threshold Graph', graphOptions: mapThresholdGraphValues({ data: content.threshold, threshold, currentRule }) },
        { title: 'Rate of change Graph', graphOptions: mapThresholdGraphValues({ data: content.roc_avg, threshold, currentRule }) }
      ]
      this.showThresholdGraph = true
      this.thresholdGraphTitle = threshold.vital
    },
    onShowGraphMobile(options) {
      this.loadThresholdGraph(options.threshold, options.currentRule)
    },
    toggleBottomSheet(key, vital) {
      this.bottomSheetKey = key
      this.bottomSheetTitle = vital
      this.showBottomSheet = true
    },
    closeModal(e) {
      this.showBottomSheet = false
    },
    onValueChange() {
      eventBus.$emit('onThresholdChange', true)
    }
  }
}
</script>

<style scoped>
  .bg-light-blue {
    background-color: #f5f6fB;
  }
</style>
