<template>
  <bh-modal-overlay :showModal="showModal" @modal-close="closeModal">
    <div class="confirmation-card bg-white display-flex flex-column justify-around minw40 minh15 ph2  scroll-y">
      <div class="display-flex flex-column text-left">
        <h2 class="display-flex ma0 pa4 pa2-mb f2 border-bottom justify-between">{{ title }} <bh-icon icon="cross" @output="closeModal"/></h2>
        <div class="display-flex flex-wrap-mb flex-column-mb">
          <div v-for="(option, index) in options" :key="index">
            <h2 v-if="option.title" class="ma0 pa3 f1-5 pb0">{{ option.title }}</h2>
            <bh-chart :options="option.graphOptions" class="chart-width chart-height"></bh-chart>
          </div>
        </div>
      </div>
    </div>
  </bh-modal-overlay>
</template>

<script>
export default {
  name: 'bh-threshold-graph',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false)
    }
  }
}
</script>

<style scoped>
.chart-height {
  height: 80vh;
}

.chart-width{
  width: 40vw;
}

@media only screen and (max-width:420px) {
  .chart-height {
    height: 40vh;
  }
  .chart-width {
    width: auto;
  }
}
</style>
