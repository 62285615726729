<template>
  <div class="display-flex flex-column align-start page-container" v-if="!loading">
    <div class="display-flex flex-column align-start" v-if="!fullScreen">
      <div class="font-bold f2 pb1">Threshold Configuration</div>
      <div class="font-bold text-grey">Please define the algorithmic rule set that would trigger the needs attention rule set.</div>
    </div>
    <div class="display-flex flex-column align-center align-start w100p pl2" v-if="fullScreen" :class="{ 'pl4 pt2': !fullScreen }">
      <div class="display-flex justify-between align-center font-bold f2 pb1 w100p mt2">
        <div class="display-flex align-center">
          <bh-icon icon="arrow-left" class="text-violet font-bold pointer" @output="toggleFullScreen"/>
          <span class="pt0-5 pl2">Create Alert Condition</span>
        </div>
        <bh-icon icon="cross" class="text-violet mr2 pointer" @output="toggleFullScreen" />
      </div>
      <div class="font-bold text-grey">Select vitals to create an alert condition.</div>
    </div>
    <div class="w100p h100p">
      <bh-configuration :class="{ 'pa2 pl0': !fullScreen }" @toggleFullScreen="toggleFullScreen" :fullScreen="fullScreen" @onTabSelect="onTabSelect" />
    </div>
     <div class="w100p h100p">
    <!-- ============================= device section ========================================= -->
    <div class="pa2 pl0">
    <bh-device-list :selectedTab="selectedTab" :isShow="isShow" :topTableHeading="topTableHeading"> </bh-device-list>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Configuration } from '@/components/configuration'
import DeviceList from '@/components/device/DeviceList'

export default {
  name: 'bh-configurations',
  components: {
    'bh-configuration': Configuration,
    'bh-device-list': DeviceList
  },
  data () {
    return {
      fullScreen: false,
      selectedTab: {},
      isShow: true,
      topTableHeading: 'Device using this rules',
      devices: [],
      showModal: false,
      deviceTableHeaders: {
        deviceId: 'Device Id',
        deviceName: 'Device Name',
        datetime: 'Last Updated'
      },
      rowsLength: ''
    }
  },
  computed: {
    ...mapGetters(['loading']),
    isSelectedRows() {
      return false
    }
  },
  methods: {
    toggleFullScreen() {
      this.fullScreen = !this.fullScreen
    },
    /**
     * toggles modal
    */
    toggleModal() {
      this.showModal = !this.showModal
    },
    onTabSelect(selectedTab) {
      this.selectedTab = selectedTab
    }
  }
}
</script>

<style scoped>
  .is-active {
    background-color: #ffffff;
  }
  .bg-grey {
    background-color: #f5f6fb;
  }
  .tab-name {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    border-radius: 5px;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rule-name >>> .vfl-label {
    text-align: left;
    font-size: 1.2rem;
    background: #fff;
    width: fit-content;
    top: -1.3rem;
    color: #666;
  }
  @media (min-width: 1024px) {
    .tabs-wrapper {
      max-width: calc(100vw - 10rem - 24px)
    }
  }
</style>
