<template>
  <div class="w100p">
      <div>{{ruleset}}</div>
    <div class="display-flex w100p tabs-wrapper overflow-auto" v-if="!fullScreen">
      <div
        class="display-flex text-violet pa2 tab-name mr-1 minw25 f1-5 align-center"
        v-for="(ruleset, index) in rulesets" :key="ruleset.id + index"
        @click="onTabSelect(ruleset, index)"
        :class="getTabClass(index, false)"
        >
        <bh-tooltip :icon="'time'" :tooltipContent="`Last updated: ${moment.formatDate(ruleset.updated_at, 'DD MMM, YYYY, HH:mm:ss')}`" class="mr1"/>
        <span class="text-elipsis maxw20 pt0-3">{{ ruleset.name }}</span>
        <bh-tooltip :tooltipContent="ruleset.name" v-if="ruleset.name.length > maxLengthForTooltip"/>
      </div>
      <div class="f3 text-violet pa2 lh2-5 tab-name w8 pointer" @click="newRuleSet" :class="getTabClass(rulesets.length - 1, true)">
        <bh-loader :size="20" :width="2" v-if="fetchingData"/>
        <span v-else>+</span>
      </div>
    </div>
    <div class="tabs-details" :class="{ 'border-grey': !fullScreen }">
      <div class="ph2 mt2 display-flex align-center-mb pos-rel border-bottom pb2">
        <bh-input
          class="pa0-5 h5 w20 w15-mb border-grey br0-5 rule-name"
          v-if="currentRuleSetIndex"
          label="Rule Set Name"
          :value="ruleSet.name"
          @keydown="onKeyPress"
          @output="onRuleSetNameChange(ruleSet, 'name', $event)"
          />
        <!-- <span class="pa1-2 f1-2" v-if="!errorMsg && currentRuleSetIndex">Please fill the values to drag the vitals for creating rule set conditions</span> -->
        <!-- <span class="pa1-2 f1-5" v-if="!errorMsg && !currentRuleSetIndex">Create default settings</span> -->
        <span class="pa1-2 f1-5 text-danger" v-if="errorMsg">{{ errorMsg }}</span>
        <div class="pos-abs right0 none-mb">
          <bh-button
            class="button button-submit inline-block mr2"
            btnClass=" pa0-7 br0-5 bg-violet text-white f2 border-none pl3 pr3"
            v-if="ruleSet.id && ruleSet.state !== 'active'"
            @output="onActivate"
            :disabled="activateStatus"
            >
            Activate
          </bh-button>
          <span
            class="inline-block mr2 pa0-5 br0-5 bg-success-light text-success f1-7 border-none pl3 pr3 font-axiroma-book"
            v-if="ruleSet.id && ruleSet.state === 'active'"
            >
            Activated
          </span>
          <bh-button
            class="button button-submit inline-block mr2"
            btnClass=" pa0-7 br0-5 bg-violet text-white f2 border-none pl3 pr3"
            @output="onSave"
            :disabled="savingStatus === 'saving'"
            v-if="isFormDrity"
            >
            Save
          </bh-button>
          <!-- display-flex  br1  bg-white f1-5 pv0-5 ph2 pointer -->
          <bh-button
            class="inline-block mr2"
            btnClass=" pa0-7 br0-5 border-violet text-violet f2 pl3 pr3"
            @output="onCancel"
            :disabled="savingStatus === 'saving'"
            v-if="isFormDrity"
            >
            Cancel
          </bh-button>
          <span v-if="savingStatus === 'saved'" class="inline-block mr2 pa0-5 br0-5 bg-success-light text-success f1-7 border-none pl3 pr3 font-axiroma-book">
            Saved
          </span>
        </div>
      </div>
      <div class="pa2" v-if="currentRuleSetIndex">
        <!-- <bh-age-selector v-if="!fullScreen"/> -->
        <bh-rule-builder class="br1 overflow-hidden br-tl0" @toggleFullScreen="toggleFullScreen" :fullScreen="!fullScreen" @onRuleConditionUpdate="onKeyPress"/>
      </div>
      <bh-thresholds v-if="currentRule" :fullScreen="!fullScreen" />
    </div>
    <div class="text-right">
      <bh-button
        class="button button-submit inline-block mt2 mb2"
        btnClass="pa0-7 br0-5 bg-white text-danger f2 border-danger pl3 pr3"
        v-if="currentRuleSetIndex && ruleSet.id && ruleSet.state !== 'active' && !fullScreen"
        @output="onSave({ shouldArchive: true })"
        :disabled="activateStatus"
        >
        <bh-loader :size="22" :width="2" loaderClasses="minw7" v-if="fetchingData" />
        <span v-else>Archive</span>
      </bh-button>
    </div>
    <div class="display-flex justify-between w100p pos-fixed bottom0 show-mb ml--3" v-if="!fullScreen">
      <bh-button
        class="bg-white text-violet light-border-violet f1-7 pa1 pt2 pb2 w50p"
        @output="onSave"
        :disabled="savingStatus === 'saving'"
        >
        Save
      </bh-button>
      <bh-button
        class="w50p bg-violet text-white light-border-violet f1-7 pa1 pt2 pb2"
        @output="onActivate"
        :disabled="activateStatus"
        >
        Activate
      </bh-button>
    </div>
     <div class="display-flex justify-between w100p pos-fixed bottom0 show-mb ml--1" v-if="fullScreen">
      <bh-button
        class="bg-white text-violet light-border-violet f1-7 pa1 pt2 pb2"
        @output="toggleFullScreen" :disabled="savingStatus === 'saving'"
        :class="fullScreen ? 'w100p': 'w50p'"
        >
        Done
      </bh-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Thresholds, RuleBuilder } from './'
import {
  saveRulesByRuleSetId,
  saveSingleRuleSet,
  validateCurrentRuleSet,
  populateExpression,
  getRuleName,
  removeRulesByRuleSetId,
  activateRuleSetByID
} from '@/services/configurations'
import { updateValue } from '@/utils'
import { eventBus } from '@/services/global-bus'
import moment from '../../../src/utils/moment'

export default {
  name: 'bh-configuration',
  components: {
    'bh-thresholds': Thresholds,
    'bh-rule-builder': RuleBuilder
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      savingStatus: '',
      activateStatus: false,
      errorMsg: '',
      ruleSetNames: [],
      fetchingData: false,
      activeRuleSetName: '',
      maxLengthForTooltip: 25,
      isFormDrity: false,
      selectedRulesetName: '',
      moment: moment,
      paramsName: ''
    }
  },
  computed: {
    ...mapGetters('configurations', ['rulesets', 'currentRuleSet', 'currentRuleSetIndex', 'currentRule', 'newRuleSetInitiated']),
    ruleSet() {
      return this.currentRuleSet ?? {}
    }
  },
  async beforeMount() {
    this.fetchingData = true
    await this.fetchAndUpdateRulesSets()
    this.ruleSetNames = this.rulesets.map(r => r.name)
    this.fetchingData = false
    this.paramsName = this.$route.params.selectedRule
    if (this.paramsName) {
      this.rulesets.forEach((ele, index) => {
        if (ele.name === this.paramsName) {
          this.onTabSelect(ele, index)
        }
      })
    }
  },
  mounted() {
    eventBus.$on('onThresholdChange', this.onKeyPress)
  },
  methods: {
    ...mapActions(['setConfirmationModal']),
    ...mapActions('configurations', ['fetchAndUpdateRulesSets', 'setCurrentRuleSetIndex', 'setCurrentRuleName', 'newRuleSet', 'setSingleProperty']),
    updateValue,
    isActive(index) {
      return this.currentRuleSetIndex === index || ((this.rulesets.length - 1) === index && !this.newRuleSetInitiated)
    },
    getTabClass(index, isNewTabButton) {
      console.log('ruleaet', this.rulesets)
      const lastTabIndex = this.newRuleSetInitiated ? (this.rulesets.length - 1) : this.rulesets.length
      if (lastTabIndex === index && !isNewTabButton) return 'none'
      if (this.isRuleActive(index) && !isNewTabButton) {
        return 'bg-success-light'
      }
      if ((lastTabIndex === this.currentRuleSetIndex && isNewTabButton) || (this.currentRuleSetIndex === index && !isNewTabButton)) {
        return 'is-active'
      }
      return 'bg-grey'
    },

    isRuleActive(index) {
      if (this.rulesets[index]?.state === 'active') {
        this.activeRuleSetName = this.rulesets[index].name
      }
      return this.rulesets[index]?.state === 'active'
    },
    async onConfirm({ shouldArchive = false }) {
      this.ruleSet.updated_at = new Date().toISOString()
      let { id, rules, removedRules, state, ...rest } = this.ruleSet
      this.savingStatus = 'saving'
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i]
        rule.expression = populateExpression(rule)
        rule.name = getRuleName(rule.ranges)
      }
      if (shouldArchive) state = 'hidden'
      try {
        // Create/Update ruleset
        const { id: ruleSetId } = await saveSingleRuleSet(id, { id, state, ...rest })
        // If ruleset is deleted, then set current rule to defalut settings and fetch all rule sets.
        if (shouldArchive) {
          this.setCurrentRuleSetIndex(0)
          return
        }
        // Create/Update all rules for ruleset
        if (rules) {
          for (const rule of rules) {
            await saveRulesByRuleSetId(ruleSetId, rule.id, rule)
          }
        }
        // Delete rules for ruleset
        if (removedRules) {
          for (const rule of removedRules) {
            if (rule.id) await removeRulesByRuleSetId(ruleSetId, rule.id, rule)
          }
        }
      } catch (err) {
        console.log(err)
        return
      }

      this.savingStatus = 'saved'
      setTimeout(() => {
        this.savingStatus = ''
      }, 3000)
    },
    onActivate() {
      // sample use case
      this.setConfirmationModal({
        title: `Are you sure you want to switch from ${this.activeRuleSetName} to ${this.ruleSet.name}?`,
        subTitle: `The alerts will be triggered for the values set in ${this.ruleSet.name}.`,
        onClick: async (confirmation) => {
          if (!confirmation) return
          this.activateStatus = true
          try {
            await activateRuleSetByID(this.ruleSet.id)
            await this.fetchAndUpdateRulesSets()
          } catch (err) {
            console.log(err)
          }
          this.activateStatus = false
        }
      })
    },
    onSave(options) {
      if (!options?.shouldArchive) {
        this.validateRulesetName()
        if (this.errorMsg) return
      }

      let title = `Are you sure you want to save the ${this.currentRuleSet?.name}?`
      if (options?.shouldArchive) {
        title = `Are you sure you want to archive the ${this.currentRuleSet?.name}? ( you will not be able to view it again )`
      }
      // sample use case
      this.setConfirmationModal({
        title,
        onClick: async (confirmation) => {
          if (!confirmation) return
          this.fetchingData = true
          await this.onConfirm(options)
          this.setSingleProperty(['newRuleSetInitiated', false])
          await this.fetchAndUpdateRulesSets()
          this.ruleSetNames = this.rulesets.map(r => r.name)
          this.fetchingData = false
        }
      })
      this.isFormDrity = false
    },
    toggleFullScreen() {
      this.$emit('toggleFullScreen')
    },
    onRuleSetNameChange(ruleSet, key, value) {
      this.validateRulesetName()
      this.updateValue(ruleSet, key, value)
    },
    onTabSelect(selectedRuleset, index) {
      this.$emit('onTabSelect', { ...selectedRuleset, index })
      this.setCurrentRuleSetIndex(index)
      this.validateRulesetName()
      this.selectedRulesetName = selectedRuleset.name
      this.onCancel()
    },
    validateRulesetName() {
      this.errorMsg = validateCurrentRuleSet(this.ruleSet, this.ruleSetNames, this.currentRuleSetIndex, this.newRuleSetInitiated)
    },
    onKeyPress() {
      this.isFormDrity = true
    },
    onCancel() {
      this.ruleSet.name = this.selectedRulesetName
      this.isFormDrity = false
    }
  }
}
</script>

<style scoped>
  .is-active {
    background-color: #ffffff;
  }
  .bg-grey {
    background-color: #f5f6fb;
  }
  .tab-name {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    border-radius: 5px;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rule-name >>> .vfl-label {
    text-align: left;
    font-size: 1.2rem;
    background: #fff;
    width: fit-content;
    top: -1.3rem;
    color: #666;
  }
  @media (min-width: 1024px) {
    .tabs-wrapper {
      max-width: calc(100vw - 10rem - 24px)
    }
  }
</style>
