<template>
  <div class="w100p text-left">
    <div class="display-flex text-violet maxw20 pa1 tab-name mr-1 w15p w45p-mb f1-5 inline-block bg-grey" v-for="(rule, index) in currentRuleSet.rules" :key="index" :class="{'is-active': currentRuleIndex === index}">
      <bh-multi-select v-if="currentRuleIndex === index" :label="`${rule.ranges.length} Age Group(s)`" @output="selectedAgeRanges($event)" :options="getOptions(rule.ranges)" emitActionButton />
      <span class="w100p display-flex align-center overflow-hidden ph1 text-violet h3 justify-center" v-else @click="setCurrentRuleIndex(index)">{{rule.ranges.length}} Age Group(s)</span>
    </div>
    <div class="display-flex text-violet maxw20 pa1 tab-name mr-1 w15p w45p-mb f1-5 inline-block bg-grey" v-if="getOptions().length">
      <bh-multi-select label="+ Age Groups" @output="selectedAgeRanges($event, true)" :options="getOptions()" showSelectAll emitActionButton />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { vitalPlaceholders, thresholdKeys, thresholdHeaders, ageRangeOptions } from '@/constants/thresholds'
import { updateValue } from '@/utils'

export default {
  name: 'bh-age-selector',
  computed: {
    ...mapGetters('configurations', ['rulesets', 'currentRuleSet', 'currentRuleIndex', 'newRuleSetInitiated']),
    selectedAgesInRuleSet() {
      return Array.prototype.concat.apply([], this.currentRuleSet.rules.map(rule => rule.ranges.map(range => `${range.min}-${range.max}`)))
    }
  },
  data() {
    return {
      thresholdHeaders,
      thresholdKeys,
      vitalPlaceholders
    }
  },
  methods: {
    updateValue,
    ...mapActions('configurations', ['setSinglePropertyCurrentRule', 'newRule', 'setCurrentRuleIndex', 'removeRule']),
    selectedAgeRanges(selectedAgeRanges, isNewRule) {
      const ageRanges = selectedAgeRanges.map(option => ({ min: option.value.split('-')[0], max: option.value.split('-')[1] }))
      if (!isNewRule && ageRanges.length) {
        this.setSinglePropertyCurrentRule({ prop: 'ranges', value: ageRanges })
      } else if (!isNewRule && !ageRanges.length) {
        this.removeRule(this.currentRuleIndex)
      } else if (isNewRule && ageRanges.length) {
        this.newRule(ageRanges)
      }
    },
    getOptions(ranges) {
      const defaultAgeRangeOptions = ageRangeOptions()
      const ageRangeOptons = []
      for (let i = 0; i < defaultAgeRangeOptions.length; i++) {
        const option = defaultAgeRangeOptions[i]
        const selected = (ranges && ranges.some(range => `${range.min}-${range.max}` === option.value)) ?? false
        if (!this.selectedAgesInRuleSet.includes(option.value) || selected) {
          ageRangeOptons.push({ ...option, selected })
        }
      }
      return ageRangeOptons
    }
  }
}
</script>

<style scoped>
  .bg-grey {
    background-color: #f5f6fb;
  }
  .is-active {
    background-color: #ffffff;
  }
  .tab-name {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    border-radius: 5px;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
