<template>
  <div class="bg-white pa2-dk pa-5-mb w100p" :class="fullScreen ? 'pa-5-mb border-grey': 'pa1-5-mb'">
    <div class="display-flex justify-between align-center" v-if="fullScreen">
      <h3 class="f1-5 ma2 text-left">Create an alert condition</h3>
      <bh-icon icon="expand" class="text-violet f1-7 mr1 show-mb pointer" @output="toggleFullScreen"/>
    </div>
    <div :class="{ 'border-grey': !fullScreen }">
      <drop
        class="display-flex flex-wrap br1 f1-3 overflow-auto"
        @drop="handleDrop"
        :class="{ 'bg-light-violet text-grey display-flex w100vw ma1-mb': !eqArr.length, 'h30 ma3 pa1-5': !fullScreen, 'h20 pl1 ml3': fullScreen }">
        <span v-if="!eqArr.length" class="justify-self-center align-self-center w100p f1-5"> Drag and Drop any vital to create rule set condition </span>
        <div v-for="(element, index) in eqArr" :key="index + element" class="inline ma1">
          <drop v-if="element === 'BOSP' && fullScreen" class="inline br0-5 bosp pa1 pointer" @drop="handleBracketDrop('BO', index, $event)">(</drop>
          <drop v-if="element === 'BCSP' && fullScreen" class="inline br0-5 bosp pa1 pointer" @drop="handleBracketDrop('BC', index, $event)">)</drop>
          <div v-if="element === 'BOSP' && !fullScreen" class="inline br0-5 bosp pa1 pointer" @click.self="handleBracketDrop('BO', index)">(</div>
          <div v-if="element === 'BCSP' && !fullScreen" class="inline br0-5 bosp pa1 pointer" @click.self="handleBracketDrop('BC', index)">)</div>
          <span v-if="element === 'BO'" class="inline br0-5 bg-violet text-white pa1">(</span>
          <span v-if="element === 'BC'" class="inline br0-5 bg-violet text-white pa1">)</span>
          <span v-if="vitals.includes(element)" class="inline br0-5 border-violet pa1 pr2">
            <span @click="removeElement(index)" class="mr1 text-violet br50p f1 pointer">X</span> {{ element }}
          </span>
          <bh-select
            class="inline br0-5 border-violet pointer pa0-2 mt-5"
            placeholder="Select"
            v-if="['&&', '||'].includes(element)"
            :options="options" @output="changeOperator(index, $event)"
            :value="element"/>
        </div>
      </drop>
    </div>
    <div class="display-flex ph2 hide-mb" v-if="eqArr.length && fullScreen">
      <h3 class="f1-5 ma0 inline mr3 ml1 align-self-center">Operators: </h3>
      <drag class="inline pa1 bg-violet text-white br0-5 mr2" transfer-data="BO">(</drag>
      <drag class="inline pa1 bg-violet text-white br0-5" transfer-data="BC">)</drag>
    </div>
    <div class="display-flex flex-wrap justify-start pt2" v-if="!fullScreen">
      <div
        v-for="key of thresholdKeys"
        :key="key"
        @click="handleDrop(vitalPlaceholders[key].key)"
        class="border-grey display-flex bg-white w31p pa1 mr-7 mb1 pointer"
        :class="{ 'disabled': eqArr.includes(vitalPlaceholders[key].key) }"
        >
        <bh-icon icon="plus" class="bg-light-violet text-violet br50p pa0-6" />
        <span class="f1-7 pl1">{{ vitalPlaceholders[key].key }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { updateCondition } from '@/services/configurations'
import { eventBus } from '@/services/global-bus'
import { vitalPlaceholders, thresholdKeys } from '@/constants/thresholds'

export default {
  name: 'bh-rule-builder',
  props: {
    fullScreen: {
      type: Boolean
    }
  },
  data() {
    return {
      options: [
        {
          label: 'And',
          value: '&&'
        },
        {
          label: 'Or',
          value: '||'
        }
      ],
      eqArr: [],
      eqFinal: this.eq || '',
      vitals: ['SD', 'TEMP', 'BU', 'CD'],
      thresholdKeys,
      vitalPlaceholders
    }
  },
  computed: {
    ...mapGetters('configurations', ['condition'])
  },
  mounted() {
    this.parseCondition()
    eventBus.$on('thresholdValueChanged', this.thresholdChanged)
  },
  watch: {
    eqArr: {
      handler() {
        this.setCondition(JSON.stringify(this.eqArr))
      }
    },
    condition: {
      handler() {
        this.parseCondition()
      }
    }
  },
  methods: {
    ...mapActions('configurations', ['setCondition']),
    thresholdChanged({ obj, thresholdKey } = {}) {
      if (obj && thresholdKey) {
        const vitalKey = this.vitalPlaceholders[thresholdKey]?.key
        const { is_included, ...vitalValues } = obj
        if (!is_included && Object.values(vitalValues).every(v => v)) {
          this.handleDrop(vitalKey)
        } else if (is_included && Object.values(vitalValues).every(v => !v)) {
          this.removeElement(this.eqArr.indexOf(vitalKey))
        }
      }
    },
    changeOperator(index, operator) {
      this.eqArr[index] = operator || '||'
      this.eqArr.splice()
    },
    parseCondition() {
      this.eqArr = this.condition ? JSON.parse(this.condition) : []
    },
    removeElement(index) {
      this.eqArr = updateCondition(this.eqArr, index)
      this.$emit('onRuleConditionUpdate', true)
    },
    handleBracketDrop(type, index, ev) {
      this.eqArr[index] = type
      this.eqArr.splice()
    },
    handleDrop(data) {
      if (!this.vitals.includes(data)) return
      if (!this.eqArr.length) {
        this.eqArr = ['BOSP', data, 'BCSP']
      } else if (!this.eqArr.includes(data)) {
        this.eqArr.push('||')
        this.eqArr.push('BOSP')
        this.eqArr.push(data)
        this.eqArr.push('BCSP')
      } else {
        console.log('duplicate drag ---> ', data)
      }
    },
    toggleFullScreen() {
      this.$emit('toggleFullScreen')
    },
    onRuleClick(rule) {
      this.eqArr.push(rule)
    }
  }
}
</script>

<style scoped>
  .bcsp, .bosp {
    border: 1px dashed #444;
  }
  /* To be used later */
  /* .remove-element {
    background: rgb(214, 219, 248);
  } */
</style>
